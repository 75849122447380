/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExtendedUser } from '@/types/extended';
import { createContext, useContext, useState } from 'react';
import { LayoutProps } from '../types/ui';

interface initialProps {
	user: ExtendedUser | null;
	setUser: (data?: any) => void;
}

const initialState: initialProps = {
	user: null,
	setUser: () => null,
};

const AuthContext = createContext(initialState);

export function AuthProvider({ children }: LayoutProps): JSX.Element {
	const data = useAuthLayer();
	return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
}

export const useAuth = (): initialProps => {
	return useContext(AuthContext);
};

const useAuthLayer = () => {
	const [user, setUser] = useState<ExtendedUser | null>(null);

	return {
		user,
		setUser,
	};
};
