import { createContext, useContext, useState } from 'react';
import { BucketListInfo } from '@/types/index';
import { LayoutProps } from '@/types/ui';

interface initialProps {
	bucketListInfo: BucketListInfo | null;
	updateBucketListInfo: (payload: BucketListInfo | null) => void;
}

const initialState: initialProps = {
	bucketListInfo: null,
	updateBucketListInfo: () => null,
};

const DataContext = createContext(initialState);

export function DataProvider({ children }: LayoutProps): JSX.Element {
	const data = useDataLayer();
	return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
}

export const useData = (): initialProps => {
	return useContext(DataContext);
};

const useDataLayer = () => {
	const [bucketListInfo, setBucketListInfo] = useState<BucketListInfo | null>(
		null
	);

	const updateBucketListInfo = (payload: BucketListInfo | null) =>
		setBucketListInfo(payload);

	return {
		bucketListInfo,
		updateBucketListInfo,
	};
};
