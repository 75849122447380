/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Image from 'next/image';
import { Box, Image as ChakraImage } from '@chakra-ui/react';
import { useState } from 'react';

interface Props {
	src: string;
	width: number;
	height: number | [number, number];
	alt: string;
	borderRadius: string;
}

export default function ProgressiveImage({ src, height, width, alt, borderRadius }: Props): JSX.Element {
	const [isLoading, setIsLoading] = useState(true);

	return (
		<Box borderRadius={borderRadius} overflow='hidden'>
			<ChakraImage
				src={src || 'https://source.unsplash.com/random'}
				minW={`${width}px`}
				height={Array.isArray(height) ? { base: height[0], md: height[1] } : `${height}px`}
				objectFit='cover'
				filter='blur(20px)'
				pos='absolute'
				inset={0}
				alt={alt}
			/>
			<Box
				transition='all .35s'
				minW={`${width}px`}
				height={Array.isArray(height) ? { base: height[0], md: height[1] } : `${height}px`}
				pos='relative'
				overflow='hidden'
				transform={isLoading ? 'scale(1.2)' : 'scale(1)'}
				opacity={isLoading ? 0 : 1}>
				<Image
					loading='lazy'
					src={src || 'https://source.unsplash.com/random'}
					objectFit='cover'
					layout='fill'
					alt={alt}
					onLoadingComplete={() => setIsLoading(false)}
				/>
			</Box>
		</Box>
	);
}
