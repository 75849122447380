import { NextSeoProps } from 'next-seo';
import { canonicalUrl } from './constants';

const seo: NextSeoProps = {
	title: 'Explore',
	description: 'Explore destinations travelers around the world have been to.',
	canonical: canonicalUrl,
	openGraph: {
		type: 'website',
		url: canonicalUrl,
		title: 'Explore',
		description: 'Explore destinations travelers around the world have been to.',
		images: [{ url: 'https://www.wheredyougo.com/banner.png' }],
		site_name: 'WheredYouGo',
	},
	additionalLinkTags: [
		{ rel: 'icon', href: 'https://www.wheredyougo.com/logo.png' },
		{ rel: 'apple-touch-icon', href: 'https://www.wheredyougo.com/logo.png', sizes: '76x76' },
	],
};

export default seo;
