import { useEffect } from 'react';
import Router from 'next/router';
import { useBeforeUnload } from 'react-use';

interface Props {
	blockPage: boolean;
	onLeave?: () => void;
}

export default function UnsavedChanges({ blockPage, onLeave }: Props): null {
	const message =
		'You have unsaved changes. If you leave this page, your changes will be lost.';

	useBeforeUnload(blockPage, message);
	useEffect(() => {
		const handler = () => {
			if (blockPage && !window.confirm(message)) {
				throw 'Route Canceled';
			} else onLeave?.();
		};

		Router.events.on('routeChangeStart', handler);

		return () => {
			Router.events.off('routeChangeStart', handler);
		};
	}, [blockPage, message, onLeave]);
	return null;
}
