import { Stack, HStack, Box, Text, VStack, SlideFade } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import Image from 'next/image';
import Avatar from '../atoms/Avatar';
import { sidebarItems } from '@/constants/index';
import SidebarItem from '../atoms/SidebarItem';
import { useState } from 'react';
import logo from '@/public/logo.png';
import AuthModal from '../molecules/Modals/AuthModal';
import QuickTrip from '../molecules/Modals/QuickTrip';

export default function Sidebar() {
	const { data: session } = useSession();
	const [isOpen, setIsOpen] = useState(false);
	const [showAnonModal, setShowAnonModal] = useState(false);

	return (
		<>
			<Stack
				d={{ base: 'none', md: 'flex' }}
				bg="black"
				px="4"
				pos="sticky"
				justifyContent="space-between"
				alignItems="center"
				top="0"
				py="4"
				maxW="300px"
			>
				<Stack spacing={16}>
					<Link href="/" passHref>
						<HStack
							as="a"
							alignItems="center"
							display={{ base: 'none', md: 'flex' }}
						>
							<Box
								h="9"
								w="9"
								pos="relative"
								userSelect="none"
								cursor="pointer"
								borderRadius="full"
								overflow="hidden"
								border="1px"
								borderColor="clay.translucent"
							>
								<Image
									src={logo}
									alt="WhererdYouGo Logo"
									layout="fill"
									placeholder="blur"
									objectFit="cover"
								/>
							</Box>
							<Text color="clay.100" fontSize="sm" fontWeight="bold">
								WheredYouGo
							</Text>
						</HStack>
					</Link>
					<VStack mt="16" spacing="3" align="flex-start">
						{sidebarItems.slice(0, 3).map((el, idx) => (
							<SidebarItem
								key={idx}
								data={el}
								openModal={(isAnon) =>
									isAnon ? setShowAnonModal(true) : setIsOpen(true)
								}
							/>
						))}
					</VStack>
				</Stack>
				<SlideFade in={Boolean(session?.user)} offsetY="20px">
					<Link href="/profile" passHref>
						<HStack
							as="a"
							py="2"
							px="3"
							minW="150px"
							spacing={4}
							rounded="md"
							cursor="pointer"
							transition="all .2s"
							_hover={{ bg: 'clay.translucent' }}
						>
							<Avatar
								size={18}
								img={session?.user?.image}
								name={session?.user?.name || session?.user?.email}
							/>
							<Text fontSize="lg" color="clay.100" fontWeight="medium">
								My Profile
							</Text>
						</HStack>
					</Link>
				</SlideFade>
			</Stack>
			<AuthModal
				allowClose
				isOpen={showAnonModal}
				handleClose={() => setShowAnonModal(false)}
			/>
			<QuickTrip isOpen={isOpen} onClose={() => setIsOpen(false)} />
		</>
	);
}
