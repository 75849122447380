/* eslint-disable indent */
import { Flex, Text, Center, Spinner } from '@chakra-ui/react';
import { sidebarItem } from '../../types/ui';
import { useRouter } from 'next/router';
import { MdAccountCircle, MdAddCircle, MdBookmark, MdExplore } from 'react-icons/md';
import { HiFire } from 'react-icons/hi';
import Avatar from './Avatar';
import { useAuth } from 'hooks/useAuth';
import { useSession } from 'next-auth/react';

interface Props {
	active?: boolean;
	openModal: (isAnon: boolean) => void;
	data: sidebarItem;
}

const SidebarItemUI = ({ data, active, openModal }: Props) => {
	const { user } = useAuth();
	const router = useRouter();
	const { data: session, status } = useSession();
	const privateRoutes = ['/new', '/bucketlists', '/profile'];
	const isPrivateRoute = privateRoutes.includes(data.route);

	const handleClick = () => {
		const anonRoutes = ['/'];

		if (anonRoutes.includes(data.route)) router.push(data.route);
		else if (isPrivateRoute) {
			if (!user) openModal(true);
			else {
				data.route === '/new' ? openModal(false) : router.push(data.route);
			}
		}
	};

	const getIcon = (icon: string) => {
		switch (icon) {
			case 'add_circle':
				return <MdAddCircle />;
			case 'explore':
				return <MdExplore />;
			case 'local_fire_department':
				return <HiFire />;
			case 'bookmark':
				return <MdBookmark />;
			default:
				return <MdAccountCircle color='#fff' />;
		}
	};

	return (
		<Flex
			as='button'
			w='full'
			p={{ base: 3, md: 2 }}
			pr={{ base: 3, md: 6 }}
			borderRadius='md'
			onClick={handleClick}
			cursor='pointer'
			bg={active ? 'clay.translucent' : 'transparent'}
			_hover={{ bg: active ? 'clay.translucent' : 'translucent' }}
			_focus={{ bg: active ? 'clay.translucent' : 'translucent' }}
			justifyContent={{ base: 'center', md: 'flex-start' }}
			fontSize={{ base: '24px', md: '18px' }}
			alignItems='center'
			color='clay.100'
			transition='all .2s'
			userSelect='none'>
			{data.route === '/profile' ? (
				status === 'loading' ? (
					<Center h='6' w='6' borderRadius='full' bg='transparent' pos='relative' overflow='hidden'>
						<Spinner color='clay.100' size='sm' />
					</Center>
				) : !session?.user ? (
					getIcon(data.icon)
				) : (
					<>
						<Avatar size={24} img={session?.user.image} name={session.user.name || session.user.email} />
					</>
				)
			) : (
				getIcon(data.icon)
			)}
			<Text fontSize='lg' ml='4' fontWeight='medium' d={{ base: 'none', md: 'block' }}>
				{data.label}
			</Text>
		</Flex>
	);
};
export default function SidebarItem(props: Props): JSX.Element {
	const router = useRouter();
	const { data } = props;

	const active =
		(router.route.startsWith(data.route) && data.route !== '/') ||
		data.route === router.route ||
		(data.route === '/bucketlists' && router.route.startsWith('/list/')) ||
		(data.route === '/' && (router.route.startsWith('/d/') || router.route.startsWith('/t/')));

	return <SidebarItemUI {...props} active={active} />;
}
