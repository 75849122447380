import { Center, Text } from '@chakra-ui/react';
import ProgressiveImage from './ProgressiveImage';

interface Props {
	img: string | null | undefined;
	name: string | null | undefined;
	size: number;
}

export default function Avatar({ name, img, size }: Props): JSX.Element {
	return (
		<Center minH={size / 4} minW={size / 4} borderRadius='full' pos='relative' bg='charcoal.50' overflow='hidden'>
			{img ? (
				<ProgressiveImage width={size} height={size} borderRadius='full' src={img} alt='profile avatar' />
			) : (
				<Text color='charcoal.800'>{name?.[0].toUpperCase()}</Text>
			)}
		</Center>
	);
}
