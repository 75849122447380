/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useState } from 'react';
import { DefaultToastData, LayoutProps, ToastMessage } from '../types/ui';

interface initialProps {
	scrolling: boolean;
	showBlModal: boolean;
	selectedImage: string;
	updateShowBlModal: (payload: boolean) => void;
	updateScrolling: (payload: boolean) => void;
	updateSelectedImage: (payload: string) => void;
}

const initialState: initialProps = {
	scrolling: false,
	selectedImage: '',
	showBlModal: false,
	updateShowBlModal: () => {},
	updateScrolling: () => {},
	updateSelectedImage: () => {},
};

const UIContext = createContext(initialState);

export function UIProvider({ children }: LayoutProps): JSX.Element {
	const data = useUILayer();
	return <UIContext.Provider value={data}>{children}</UIContext.Provider>;
}

export const useUI = (): initialProps => {
	return useContext(UIContext);
};

const useUILayer = () => {
	const [toastMessage, setToastMessage] = useState<DefaultToastData>({
		show: false,
	});
	const [modalToastMessage, setModalToastMessage] = useState<ToastMessage>({
		message: '',
		type: '',
		show: false,
		duration: 0,
	});
	const [scrolling, setScrolling] = useState<boolean>(false);
	const [showBlModal, setShowBlModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState<string>('');

	const updateModalToastMessage = (payload: ToastMessage) => {
		setModalToastMessage(payload);

		setTimeout(() => {
			setModalToastMessage({
				...payload,
				show: false,
			});
		}, payload.duration);
	};
	const updateScrolling = (payload: boolean) => setScrolling(payload);
	const updateShowBlModal = (payload: boolean) => setShowBlModal(payload);
	const updateSelectedImage = (payload: string) => setSelectedImage(payload);

	return {
		scrolling,
		toastMessage,
		updateModalToastMessage,
		updateScrolling,
		showBlModal,
		selectedImage,
		updateSelectedImage,
		updateShowBlModal,
	};
};
