/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Box,
	useRadioGroup,
	useRadio,
	Text,
	Stack,
	Flex,
	Collapse,
} from '@chakra-ui/react';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import DefaultInput from '../atoms/Inputs/DefaultInput';
import { MdPlace } from 'react-icons/md';
import { validateValue, Mater } from '@/utils/index';
import { AutocompleteResponse } from '@/types/index';

interface Props {
	isError: boolean;
	validate: boolean;
	location: string;
	disabled?: boolean;
	handleItemClick: (field: string, value: any) => void;
}
interface UIProps {
	results: AutocompleteResponse[];
	handleChange: (val: string) => void;
	setResults: Dispatch<SetStateAction<AutocompleteResponse[]>>;
}

const Item = (props: any) => {
	const { getInputProps, getCheckboxProps } = useRadio(props);
	const input = getInputProps();
	const checkbox = getCheckboxProps();

	const highlighted = { bg: 'gray.50' };
	return (
		<Box
			as="button"
			w="100%"
			onClick={props.onClick}
			_focus={highlighted}
			_hover={highlighted}
			cursor="pointer"
			transition="all .2s"
		>
			<input {...input} style={{ display: 'none' }} />
			<Flex
				{...checkbox}
				color="charcoal.800"
				fontWeight="medium"
				alignItems="center"
				p="12px"
				borderTop={props.pos ? '1px' : 'none'}
				borderColor="#f5f5f5"
			>
				<MdPlace size={24} />
				{props.children}
			</Flex>
		</Box>
	);
};
const AutocompleteUI = ({
	results,
	handleItemClick,
	setResults,
	isError,
	disabled,
	handleChange,
}: Props & UIProps): JSX.Element => {
	const ref = useRef<HTMLDivElement>(null);
	const initialFocusRef = useRef(null);

	const { getRootProps, getRadioProps } = useRadioGroup({
		name: 'location',
		onChange: console.log,
	});

	const group = getRootProps();

	return (
		<Box as="div" w="100%" ref={ref}>
			<DefaultInput
				isDisabled={disabled}
				placeholder="Enter a location"
				name="location"
				validateOnBlur
				validationFn={validateValue}
				isError={isError}
				handleChange={handleChange}
			/>
			<Collapse in={Boolean(results?.length)} animateOpacity>
				<Stack
					{...group}
					spacing={0}
					direction="column"
					maxH="200px"
					align="flex-start"
					overflowY="scroll"
					ref={initialFocusRef}
				>
					{results?.map((el: AutocompleteResponse, idx: number) => {
						const radio = getRadioProps({ value: el.city });

						return (
							<Item
								key={idx}
								pos={idx}
								{...radio}
								onClick={() => {
									handleItemClick('location', el.city.split(' - ')[0]);
									setResults([]);
								}}
							>
								<Text ml="8px" textAlign="start">
									{el.city}, {el.state}
									<Text as="span" color="gray.400">
										- {el.countryCode}
									</Text>
								</Text>
							</Item>
						);
					})}
				</Stack>
			</Collapse>
		</Box>
	);
};

export default function Autocomplete(props: Props): JSX.Element {
	const [results, setResults] = useState<AutocompleteResponse[]>([]);

	const autocomplete = async (val: string) => {
		const res = await Mater(val);
		setResults(res);
	};

	const handleChange = (val: string) => {
		if (val.length > 2) autocomplete(val);
		else setResults([]);
	};

	return (
		<AutocompleteUI
			{...props}
			results={results}
			handleChange={handleChange}
			setResults={setResults}
		/>
	);
}
