import { sidebarItems } from '@/constants/index';
import { Grid } from '@chakra-ui/react';
import React, { useState } from 'react';
import SidebarItem from '../atoms/SidebarItem';
import AuthModal from '../molecules/Modals/AuthModal';
import QuickTrip from '../molecules/Modals/QuickTrip';
// import AuthModal from '../molecules/Modals/AuthModal';
// import QuickTrip from '../molecules/Modals/QuickTrip';

const MobileSidebarUI = () => {
	const items = [...sidebarItems];
	const [isOpen, setIsOpen] = useState(false);
	const [showAnonModal, setShowAnonModal] = useState(false);

	return (
		<>
			<Grid
				templateColumns="repeat(5, 1fr)"
				d={{ md: 'none', base: 'flex' }}
				w="100%"
				gap="8px"
				py="16px"
				px="12px"
				bg="black"
				color="#fff"
				zIndex="docked"
			>
				{items.map((el, idx) => (
					<SidebarItem
						key={idx}
						data={el}
						openModal={(isAnon) =>
							isAnon ? setShowAnonModal(true) : setIsOpen(true)
						}
					/>
				))}
			</Grid>
			<AuthModal
				allowClose
				isOpen={showAnonModal}
				handleClose={() => setShowAnonModal(false)}
			/>
			<QuickTrip isOpen={isOpen} onClose={() => setIsOpen(false)} />
		</>
	);
};

export default function MobileSidebar(): JSX.Element {
	return <MobileSidebarUI />;
}
