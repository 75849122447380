import { LayoutProps } from '@/types/ui';
import { Box } from '@chakra-ui/react';
import { useAuth } from 'hooks/useAuth';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

export default function Layout({ children }: LayoutProps) {
	const { data: session } = useSession();
	const { setUser } = useAuth();

	const getUser = async () => {
		const res = await fetch(`/api/user?email=${session?.user?.email}`);
		const data = await res.json();
		setUser(data);
	};

	useEffect(() => {
		if (session) getUser();
	}, [session]);

	return (
		<Box
			d="flex"
			w="100vw"
			h="100%"
			bg="clay.100"
			pos="relative"
			overflow="hidden"
		>
			{children}
		</Box>
	);
}
