import { ExtendedUser } from '@/types/extended';
import { useSession } from 'next-auth/react';
import useSWR from 'swr';

const fetcher = (url: string, email: string) =>
	fetch(`${url}?email=${email}`).then((r) => r.json());

export default function useUser() {
	const { data: session } = useSession();

	const { data, error, mutate } = useSWR<ExtendedUser>(
		session ? ['/api/user', session?.user?.email] : null,
		fetcher
	);
	return { user: data, isLoading: !error && !data, isError: error, mutate };
}
