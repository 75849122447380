import { Flex, Text } from '@chakra-ui/react';

interface Props {
	description: string;
	status: 'info' | 'success';
	action: () => void;
	actionLabel?: string;
}
export default function CustomToast({
	description,
	status,
	action,
	actionLabel,
}: Props): JSX.Element {
	return (
		<Flex
			p="3"
			minW="300px"
			rounded="md"
			userSelect="none"
			cursor="pointer"
			onClick={action}
			justifyContent="space-between"
			alignItems="center"
			bg={status === 'info' ? 'charcoal.800' : 'green.500'}
		>
			<Text fontWeight="medium" color="white">
				{description}
			</Text>
			{actionLabel && (
				<Text
					py="1"
					px="2"
					rounded="md"
					bg="rgba(255,255,255,.15)"
					fontWeight="medium"
					fontSize="xs"
					color="white"
				>
					{actionLabel}
				</Text>
			)}
		</Flex>
	);
}
