import { LayoutProps } from '@/types/ui';
import { Center } from '@chakra-ui/react';

export default function Content({ children }: LayoutProps) {
	return (
		<Center
			d="flex"
			pos="relative"
			flex={1}
			flexDirection="column"
			h="100%"
			bg="clay.100"
			w="100%"
			m="auto"
			maxW="5xl"
			overflow="scroll"
		>
			{children}
		</Center>
	);
}
