/* eslint-disable indent */
import { sidebarItem } from '@/types/ui';

export const sidebarItems: sidebarItem[] = [
	{ icon: 'explore', label: 'Explore', route: '/' },
	{ icon: 'add_circle', label: 'Add a trip', route: '/new' },
	{ icon: 'bookmark', label: 'Bucket Lists', route: '/bucketlists' },
	{ icon: 'account_circle', label: 'Profile', route: '/profile' },
];

export const banner = 'https://www.wheredyougo.com/banner.png';
export const explore = 'https://www.wheredyougo.com/explore.png';

export const cloudinaryUrl = 'https://res.cloudinary.com/wheredyougo-new/image/upload/';

export const canonicalUrl: string =
	process.env.NEXT_PUBLIC_TARGET_ENV === 'dev'
		? 'http://localhost:3000'
		: process.env.NEXT_PUBLIC_TARGET_ENV === 'prod'
		? 'https://wheredyougo.com'
		: 'https://staging.wheredyougo.com';

export const steppers = ['Basic Info', 'Things to do', 'Housing', 'Photos'];

export const months: string[] = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];
