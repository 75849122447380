import { Box, Center } from '@chakra-ui/react';
import { useUI } from 'hooks/useUI';
import Image from 'next/image';

export default function ImageViewer() {
	const { selectedImage, updateSelectedImage } = useUI();

	return !selectedImage ? null : (
		<Center
			w="full"
			h="full"
			bg="rgba(0,0,0,.75)"
			pos="absolute"
			zIndex="banner"
			inset={0}
			onClick={() => updateSelectedImage('')}
		>
			<Box
				pos="relative"
				id="container"
				w={{ base: '95%', md: '75%' }}
				h={{ base: '75%', md: '83%' }}
				rounded="md"
				overflow="hidden"
			>
				<Image src={selectedImage} layout="fill" objectFit="contain" />
			</Box>
		</Center>
	);
}
