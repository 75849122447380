import {
	FormControl,
	FormHelperText,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
} from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';

interface Props {
	name: string;
	label?: string;
	validate?: boolean;
	error?: string | undefined;
	placeholder: string;
	helperText?: string;
	validateOnBlur?: boolean;
	isError: boolean;
	isDisabled?: boolean;
	handleChange?: (val: string) => void;
	handleBlur?: (val: string) => void;
	leftIcon?: JSX.Element | null;
	validationFn?: (value: string) => string | undefined;
}

export default function DefaultInput({
	name,
	label,
	placeholder,
	helperText,
	error,
	isError,
	validate = true,
	handleChange,
	handleBlur,
	isDisabled,
	validateOnBlur,
	leftIcon,
	validationFn,
}: Props): JSX.Element {
	return (
		<Field name={name} validate={validate && validationFn}>
			{({ field, form }: FieldProps) => (
				<FormControl isInvalid={isError}>
					{label && <FormLabel htmlFor={name}>{label}</FormLabel>}
					<InputGroup>
						{leftIcon && leftIcon}
						<Input
							{...field}
							placeholder={placeholder}
							onBlur={(e) => {
								field.onBlur(e);
								handleBlur && handleBlur(e.target.value);
								validateOnBlur && form.validateField(name);
							}}
							onChange={(e) => {
								field.onChange(e);
								handleChange && handleChange(e.target.value);
							}}
							disabled={isDisabled}
						/>
					</InputGroup>
					{error || form.errors?.[name] ? (
						<FormErrorMessage>{error || form.errors?.[name]}</FormErrorMessage>
					) : (
						helperText && <FormHelperText>{helperText}</FormHelperText>
					)}
				</FormControl>
			)}
		</Field>
	);
}
