import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
	colors: {
		translucent: 'rgba(255,255,255,.15)',
		gold: {
			50: '#fff8df',
			400: '#FEC601',
		},
		clay: {
			translucent: 'rgba(244, 244, 249,.2)',
			100: '#F4F4F9',
			300: '#dedee3',
		},
		lightblue: {
			100: '#d1d8dc',
			600: '#586F7C',
		},
		charcoal: {
			50: '#E7EFF5',
			100: '#c9d8df',
			200: '#a9bdc7',
			800: '#2F4550',
		},
		ruby: {
			50: '#FEF2F2',
			200: '#FECACA',
			800: '#991B1B',
		},
		amber: {
			50: '#FFFBEB',
			200: '#FDE68A',
			800: '#92400E',
		},
		emerald: {
			50: '#ECFDF5',
			200: '#A7F3D0',
			800: '#065F46',
		},
		violet: {
			50: '#F5F3FF',
			200: '#DDD6FE',
			800: '#5B21B6',
		},
	},
});

export const colorVariants = [
	{
		label: 'Charcoal',
		bg: 'charcoal.50',
		fg: 'charcoal.800',
		ring: 'charcoal.200',
	},
	{
		label: 'Ruby',
		bg: 'ruby.50',
		fg: 'ruby.800',
		ring: 'ruby.200',
	},
	{
		label: 'Amber',
		bg: 'amber.50',
		fg: 'amber.800',
		ring: 'amber.200',
	},
	{
		label: 'Emerald',
		bg: 'emerald.50',
		fg: 'emerald.800',
		ring: 'emerald.200',
	},
	{
		label: 'Violet',
		bg: 'violet.50',
		fg: 'violet.800',
		ring: 'violet.200',
	},
];
