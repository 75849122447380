/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Button,
	Heading,
	VStack,
	Text,
	InputLeftElement,
	Stack,
	Divider,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import DefaultInput from '../../atoms/Inputs/DefaultInput';
import { FaFacebook } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { useSession, signIn } from 'next-auth/react';
import { MdEmail } from 'react-icons/md';
import { validateEmail } from '@/utils/index';

interface Props {
	isOpen?: boolean;
	allowClose?: boolean;
	handleClose?: () => void;
}

export default function AuthModal({
	isOpen,
	allowClose,
	handleClose,
}: Props): JSX.Element | null {
	const { data: session, status } = useSession();
	const priority = isOpen !== undefined;

	return status === 'loading' ? null : (
		<Modal
			// this is a hack to make sure that the modal can be displayed even
			// when there is no session.
			isOpen={isOpen || (!priority && !session)}
			onClose={() => handleClose?.()}
			isCentered
			size="2xl"
			motionPreset="slideInBottom"
			scrollBehavior="inside"
		>
			<ModalOverlay />
			<ModalContent
				borderBottomRadius={{ base: '0', md: 'md' }}
				w={{ base: '100vw', md: '100%' }}
				pos={{ base: 'absolute', md: 'relative' }}
				m={{ base: '0', md: '3.75rem' }}
				bottom="0"
			>
				<ModalHeader textAlign="center">
					<Heading as="h2" size="xl">
						Sign in to WheredYouGo
					</Heading>
				</ModalHeader>
				{allowClose && <ModalCloseButton />}
				<ModalBody pb="32px">
					<Formik
						initialValues={{ email: '' }}
						onSubmit={(values) => {
							signIn('email', { email: values.email });
						}}
						validateOnBlur={false}
						validateOnChange={false}
					>
						{({ errors }) => (
							<Form>
								<Stack spacing={6} alignItems="center">
									<VStack w={{ base: '100%', md: '75%' }}>
										<Button
											w="100%"
											color="charcoal.800"
											leftIcon={<FcGoogle />}
											borderRadius="full"
											onClick={() => signIn('google')}
										>
											Continue with Google
										</Button>
										<Button
											w="100%"
											color="charcoal.800"
											leftIcon={<FaFacebook color="#4267B2" />}
											onClick={() => signIn('facebook')}
											borderRadius="full"
										>
											Continue with Facebook
										</Button>
									</VStack>

									<Stack
										w={{ base: '100%', md: '75%' }}
										direction="row"
										alignItems="center"
									>
										<Divider />
										<Text color="charcoal.800">or</Text>
										<Divider />
									</Stack>
									<VStack w={{ base: '100%', md: '75%' }} spacing={6}>
										<DefaultInput
											name="email"
											validateOnBlur
											validationFn={validateEmail}
											placeholder="Email"
											helperText="We'll send you a magic link to this email"
											isError={Boolean(errors.email)}
											leftIcon={
												<InputLeftElement pointerEvents="none">
													<MdEmail size={18} color="#2F4550" />
												</InputLeftElement>
											}
										/>
										<Button
											w="100%"
											type="submit"
											bg="charcoal.800"
											e
											color="#fff"
											_hover={{}}
										>
											Continue
										</Button>
									</VStack>
								</Stack>
							</Form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
