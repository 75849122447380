/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	HStack,
	Box,
	Text,
	Heading,
	Stack,
	ModalFooter,
	Button,
	useToast,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { BsLightning } from 'react-icons/bs';
import { IoNewspaperOutline } from 'react-icons/io5';
import { Form, Formik } from 'formik';
import DefaultInput from '../../atoms/Inputs/DefaultInput';
import Autocomplete from '../Autocomplete';
import UnsavedChanges from '../../atoms/UnsavedChanges';
import CustomToast from '@/components/atoms/CustomToast';
import { validateValue } from '@/utils/index';
import useNewTrip from 'hooks/mutations/useNewTrip';
import useUser from 'hooks/useUser';
import { Trip } from '@prisma/client';
import { ExtendedUser } from '@/types/extended';

interface UIProps extends Props {
	intent: string;
	initialValues: {
		name: string;
		location: string;
	};
	loading: boolean;
	handleSubmit: (values: any) => void;
	handleRegularTrip: () => void;
	setIntent: Dispatch<SetStateAction<string>>;
}
interface Props {
	isOpen: boolean;
	onClose: () => void;
}

const QuickTripUI = ({
	isOpen,
	onClose,
	intent,
	handleSubmit,
	loading,
	initialValues,
	setIntent,
	handleRegularTrip,
}: UIProps) => {
	const title = intent === 'quick' ? 'Quick Trip' : 'What type of trip?';

	const handleClose = () => {
		setIntent('');
		onClose();
	};

	return (
		<Modal isOpen={isOpen} isCentered onClose={handleClose} motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent
				borderBottomRadius={{ base: '0', md: 'md' }}
				w={{ base: '100vw', md: '100%' }}
				pos={{ base: 'absolute', md: 'relative' }}
				m={{ base: '0', md: '3.75rem' }}
				bottom='0'>
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				<Formik
					initialValues={initialValues}
					onSubmit={(values) => {
						handleSubmit(values);
					}}
					validateOnBlur={false}
					validateOnChange={false}>
					{({ values, touched, errors, dirty, isSubmitting, setFieldValue }) => (
						<>
							<Form>
								<ModalBody p='3'>
									{intent === 'quick' ? (
										<Box>
											<UnsavedChanges blockPage={dirty && !isSubmitting} />
											<Stack>
												<DefaultInput
													name='name'
													validateOnBlur
													validationFn={validateValue}
													placeholder='Trip name'
													isError={Boolean(errors.name)}
												/>
												<Autocomplete
													location={values.location}
													validate={Boolean(touched.location)}
													isError={Boolean(errors.location)}
													handleItemClick={setFieldValue}
												/>
											</Stack>
										</Box>
									) : (
										<Stack spacing={1}>
											<Box
												as='button'
												type='button'
												borderRadius='1px'
												rounded='md'
												bg='#fafafa'
												_hover={{ bg: '#f5f5f5' }}
												w='full'
												p='2'
												onClick={() => setIntent('quick')}>
												<HStack spacing={4}>
													<BsLightning color='#2F4550' size={24} />
													<Stack justifyContent='start' spacing='1'>
														<Heading
															as='h3'
															fontSize='xl'
															textAlign='start'
															color='charcoal.800'>
															Quick Trip
														</Heading>
														<Text fontWeight='light' fontSize='sm' textAlign='start'>
															Quickly save a trip to your drafts
														</Text>
													</Stack>
												</HStack>
											</Box>
											<Box
												as='button'
												type='button'
												borderRadius='1px'
												rounded='md'
												bg='#fafafa'
												_hover={{ bg: '#f5f5f5' }}
												w='full'
												p='2'
												onClick={handleRegularTrip}>
												<HStack spacing={4}>
													<IoNewspaperOutline color='#2F4550' size={24} />
													<Stack justifyContent='start' spacing='1'>
														<Heading
															as='h3'
															fontSize='xl'
															textAlign='start'
															color='charcoal.800'>
															Regular Trip
														</Heading>
														<Text fontWeight='light' fontSize='sm' textAlign='start'>
															Document aspects of your trip like housing and photos
														</Text>
													</Stack>
												</HStack>
											</Box>
										</Stack>
									)}
								</ModalBody>
								<ModalFooter
									d={intent === 'quick' ? 'flex' : 'none'}
									pt='0'
									px='3'
									justifyContent='flex-end'>
									<Button
										mr={2}
										type='button'
										variant='ghost'
										colorScheme='gray'
										onClick={() => setIntent('')}>
										Back
									</Button>
									<Button colorScheme='blue' loadingText='Saving' isLoading={loading} type='submit'>
										Save Draft
									</Button>
								</ModalFooter>
							</Form>
						</>
					)}
				</Formik>
			</ModalContent>
		</Modal>
	);
};

export default function QuickTrip(props: Props): JSX.Element {
	const toast = useToast();
	const router = useRouter();
	const { user, mutate } = useUser();
	const [intent, setIntent] = useState('');
	const [loading, setLoading] = useState(false);

	const handleRegularTrip = () => {
		props.onClose();
		router.push('/new');
	};

	const initialValues = {
		name: '',
		location: '',
	};

	const handleSubmit = async (values: any) => {
		const data = {
			...values,
			date: '',
			ttd: [{ text: '' }],
			photos: [],
			author: user?.id,
			draft: true,
			housing: { type: '', link: '', description: '', photos: [] },
		};

		mutate(useNewTrip({ ...data, draft: true }), {
			optimisticData: {
				...user,
				trips: [{ ...data }, ...(user?.trips as Trip[])],
			} as ExtendedUser,
			populateCache: true,
			revalidate: true,
			rollbackOnError: true,
		});
		setLoading(false);
		props.onClose();

		toast({
			duration: 1500,
			isClosable: true,
			position: 'top-right',
			render: () => (
				<CustomToast
					description='Draft saved.'
					status='success'
					actionLabel='VIEW'
					action={() => router.push('/profile')}
				/>
			),
		});
	};

	return (
		<QuickTripUI
			{...props}
			intent={intent}
			loading={loading}
			setIntent={setIntent}
			handleSubmit={handleSubmit}
			initialValues={initialValues}
			handleRegularTrip={handleRegularTrip}
		/>
	);
}
