import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { SWRConfig } from 'swr';
import { ChakraProvider } from '@chakra-ui/react';
import { fetcher } from 'utils';
import { theme } from 'theme';
import Sidebar from '@/components/organisms/Sidebar';
import Content from '@/components/atoms/Layouts/Content';
import Layout from '@/components/atoms/Layouts';
import MobileSidebar from '@/components/organisms/MobileSidebar';
import { AuthProvider } from 'hooks/useAuth';
import { DataProvider } from 'hooks/useData';
import ImageViewer from '@/components/molecules/ImageViewer';
import { UIProvider } from 'hooks/useUI';
import { DefaultSeo } from 'next-seo';
import seo from 'seo';

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
	return (
		<SWRConfig value={{ fetcher }}>
			<SessionProvider session={session}>
				<AuthProvider>
					<UIProvider>
						<DataProvider>
							<ChakraProvider theme={theme}>
								<DefaultSeo {...seo} />
								<Layout>
									<Sidebar />
									<Content>
										<Component {...pageProps} />
										<MobileSidebar />
									</Content>
									<ImageViewer />
								</Layout>
							</ChakraProvider>
						</DataProvider>
					</UIProvider>
				</AuthProvider>
			</SessionProvider>
		</SWRConfig>
	);
}

export default MyApp;
