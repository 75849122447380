export const fetcher = (url: RequestInfo) => fetch(url).then((r) => r.json());

/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloudinaryUrl } from '../constants';
import { AutocompleteResponse } from '../types/index';

export const capitalize = (data: string): string =>
	data
		.split(' ')
		.map((el) => el[0]?.toUpperCase() + el?.substring(1))
		.join(' ');

export const asyncFilter = async (
	arr: any[],
	predicate: (...args: any[]) => Promise<boolean>
): Promise<any> =>
	Promise.all(arr.map(predicate)).then((results) =>
		arr.filter((_: any, index) => results[index])
	);

export const validateEmail = (email: string): string | undefined => {
	if (!email) return 'Email cannot be empty';
	const invalid = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
	return invalid ? 'Invalid email address' : undefined;
};

export const validateItems = (items: string[]): string | undefined => {
	if (!items.length) return 'Required';
	return undefined;
};
export const validateURL = (url: string): string | undefined => {
	if (!url) return 'This cannot be empty';
	const invalid = !/(https?:\/\/[^ ]*)/gi.test(url);
	return invalid
		? 'Invalid url. Use format: https://www.example.com'
		: undefined;
};
export const validatePassword = (pw: string): string | undefined => {
	if (!pw) return 'Password cannot be empty';
	if (pw.length < 6) return 'Must have at least 6 characters';
	return undefined;
};
export const validateValue = (val: string): string | undefined => {
	if (!val) return 'Required';
	return undefined;
};

export const Mater = async (query: string): Promise<AutocompleteResponse[]> => {
	const res = await fetch(
		`https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?query=${query}&apiKey=mDgiZGO-AZWp-Fp4MYiG-85Q-Xl5p-cFacwrvDqKTMQ`,
		{ method: 'GET' }
	);
	const data = await res.json();
	// console.log({ suggestions: data.suggestions });
	const addresses: AutocompleteResponse[] = data.suggestions
		?.filter((el: any) => !(!el.address.city || !el.address.state))
		.map((el: any) => ({
			countryCode: el.countryCode,
			city: el.address.city,
			state: el.address.state,
			county: el.address.county,
			country: el.address.country,
		}));

	const formatted = addresses.reduce((acc: AutocompleteResponse[], curr) => {
		if (!acc.find((el) => el.city === curr.city && el.state === curr.state)) {
			acc.push(curr);
		}
		return acc;
	}, []);

	return formatted;
};

const getCloudinaryPath = (url: string): string => {
	const id = url.split(cloudinaryUrl)[1];
	return id.split('/').slice(1).join('/');
};
export const formatImageUrl = (url: string): string =>
	getCloudinaryPath(url).split('.')[0];

export const getCloudinaryFolder = (url: string): string =>
	getCloudinaryPath(url).split('/')[1];
